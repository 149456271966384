<template>
  <div
    id="Client"
    class="d-flex flex-column flex-nowrap justify-content-between"
  >
    <the-navbar></the-navbar>
    <social-bar></social-bar>
    <router-view class="flex-grow-1" v-slot="{ Component }">
      <component :is="Component"/>
    </router-view>
    <The-footer></The-footer>
  </div>
</template>

<script>
import SocialBar from "@/components/SocialBar/SocialBar.vue";
import TheNavbar from "@/components/TheNavbar/TheNavbar.vue";
import TheFooter from "@/components/TheFooter/TheFooter.vue";

export default {
  components: {
    SocialBar,
    TheNavbar,
    TheFooter,
  },
  computed: {
    image() {
      switch (this.$route.name) {
        case "home":
          return require("@/assets/image5.jpeg");
        case "horaire":
          return require("@/assets/DSC_0030.jpeg");
        case "prix":
          return require("@/assets/DSC_9937-2.jpeg");
        case "media":
          return require("@/assets/image1.jpeg");
        case "map":
          return require("@/assets/image6.jpeg");
        default:
          return require("@/assets/4X2A7871(4).jpeg");
      }
    },
  },
};
</script>

<style src="./Client.css" scoped></style>
